import { useContext } from "react";
import { AppContext } from "../App";
import { getReferenceFromId } from "../Data";
import SectionEndCard from "../components/SectionEndCard";

export default function SectionCompleteView () {
	const {state: appState} = useContext(AppContext)

	return (<>
		<div className="flex-grow"/>
		<SectionEndCard
			startRef={getReferenceFromId(appState.sectionStartId)}
			endRef={getReferenceFromId(appState.sectionEndId)}
		/>
		<div className="flex-grow"/>
	</>)
}