import { useContext } from "react";
import { AppContext } from "../App";
import { getReferenceFromId } from "../Data";
import SectionDeleteCard from "../components/SectionDeleteCard";

export default function SectionDeleteView () {
	const {state: appState} = useContext(AppContext)

	return (<>
		<div className="flex-grow"/>
		<SectionDeleteCard
			startRef={getReferenceFromId(appState.sectionStartId)}
			endRef={getReferenceFromId(appState.sectionEndId)}
		/>
		<div className="flex-grow"/>
	</>)
}