import { useContext, useEffect, useState } from 'react'
import { FiBookmark, FiPlus, FiEdit3 } from 'react-icons/fi'
import { AppContext, highlighterTypeEnum, sectionViewEnum } from '../App'
import { ReactComponent as ShortLogo } from '../img/27V.svg'

export default function Footer () {
	const {state: appState, dispatch: appDispatch} = useContext(AppContext)
	const [bShowHighlighters, setShowHighlighters] = useState()
	const [highlightersTranslateCss, setHighlightersTranslateCss] = useState("translate-y-12")

	useEffect(() => {
		if (bShowHighlighters) {
			setHighlightersTranslateCss("translate-y-0")
		}
		else {
			setHighlightersTranslateCss("translate-y-12")
		}
	}, [bShowHighlighters])

	function onMenuClicked () {
		appDispatch({type: 'show-menu'})
	}

	function onHighlightClicked () {
		setShowHighlighters(!bShowHighlighters)
	}

	function onHighlighterClicked (index) {
		if (index === appState.verseHighlight) {
			appDispatch({type: 'set-highlight', data: highlighterTypeEnum.NONE})
		}
		else {
			appDispatch({type: 'set-highlight', data: index})
		}
	}

	const highlighterData = [
		{
			// glad
			emojiCode: "&#128578;",
			fromColor: "from-yellow-500",
			toColor: "to-yellow-300",
		},
		{
			// sad
			emojiCode: "&#128546;",
			fromColor: "from-blue-500",
			toColor: "to-blue-300",
		},
		{
			// mad
			emojiCode: "&#128544;",
			fromColor: "from-red-500",
			toColor: "to-red-300",
		},
		{
			// laughing
			emojiCode: "&#128518;",
			fromColor: "from-orange-500",
			toColor: "to-orange-300",
		},
		{
			// thinking
			emojiCode: "&#129300;",
			fromColor: "from-purple-500",
			toColor: "to-purple-300",
		},
		{
			// relieved
			emojiCode: "&#128524;",
			fromColor: "from-green-500",
			toColor: "to-green-300",
		},
	]

	return (
		<div className="flex flex-row flex-wrap-reverse justify-between h-9">
			<div className="flex justify-between basis-full bg-gainsboro z-10 pb-1 -mb-1">
				<button
					className="text-2xl min-w-[40px] text-midgray border-2 border-midgray rounded-lg p-1"
					onClick={onHighlightClicked}>
					<FiEdit3 className="mx-auto"/>
				</button>

				{appState.sectionKeys &&
					<div className="flex justify-center items-center text-midgray">
						{appState.sectionKeys.split(";").filter(k => k).map((k, i) => {
							return (
								<BookmarkIcon
									key={k}
									highlighted={i === appState.sectionsIndex && appState.sectionView !== sectionViewEnum.SELECT}
								/>
							)
						})}

						<FiPlus className="text-sm"/>
					</div>
				}

				<button
					className="min-w-[40px] text-midgray border-2 border-midgray rounded-lg p-1"
					onClick={onMenuClicked}>
					<ShortLogo className="w-7 mx-auto" fill="#808080"/>
				</button>
			</div>

			<div
				className={`flex flex-row justify-between basis-full ${highlightersTranslateCss} mb-2 transition-transform`}>
				{highlighterData.map((d, i) => {
					let selected = (i+1) === appState.verseHighlight
					let grayscale = appState.verseHighlight !== highlighterTypeEnum.NONE
					return (
						<button
							key={i}
							className={`p-1 rounded-lg leading-none text-3xl ${d.fromColor} ${d.toColor}
											${selected ? "bg-gradient-to-t" : grayscale ? "grayscale" : ""}`}
							dangerouslySetInnerHTML={{__html: d.emojiCode}}
							onClick={e => onHighlighterClicked(i+1)}>
						</button>
					)
				})}
			</div>
		</div>
	)
}

function BookmarkIcon (props) {
  const [opacityCss, setOpacityCss] = useState("opacity-0")

	useEffect(() => {
		setOpacityCss("opacity-100")
	}, [])

	let fontSize = props.highlighted ? "text-2xl" : "text-sm"

	return (
		<div className={`${fontSize} ${opacityCss} transition-fontsize-opacity ease-out duration-150`}>
			<FiBookmark/>
		</div>
	)
}