import { useContext } from "react"
import { AppContext } from "../App"

export default function SectionEndCard ({startRef, endRef}) {
	const {dispatch: appDispatch} = useContext(AppContext)

	function onClickRestart () {
		appDispatch({type: 'restart-section'})
	}

	return (<>
		<div className="rounded-lg bg-whitesmoke shadow-slant shadow-midgray px-4 py-6">
			<p
				className="leading-tight text-center text-3xl font-bold">
				Section Complete
			</p>
			<p
				className="leading-tight text-center font-marker">
				{startRef} - {endRef}
			</p>
		</div>

		<div
			className="flex justify-center mt-6">
			<button
				className="block bg-whitesmoke shadow-slant shadow-green-500 px-4 py-2 rounded-lg font-bold"
				onClick={onClickRestart}>
				Read Again
			</button>
		</div>
	</>)
}