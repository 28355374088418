import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
} from 'react'

import {
  DEFAULT_CHAPTER_LENGTH,
  fetchNextChunk,
  getChunkEndVerseId,
  getReferenceFromId,
  getRelativeVerseId,
  getVerseCount,
  VERSES_BEFORE_NEXT_CHUNK
} from './Data'

import VerseView from './views/VerseView'
import SectionSelectView from './views/SectionSelectView'
import SectionDeleteView from './views/SectionDeleteView'
import SectionCompleteView from './views/SectionCompleteView'
import MenuView from './views/MenuView'

const AppContext = createContext()

var enumCount = 0
const sectionViewEnum = {
  NONE: enumCount++,
  SELECT: enumCount++,
  VERSE: enumCount++,
  END: enumCount++,
  DELETE: enumCount++,
  MENU: enumCount++,
}

enumCount = 0
const highlighterTypeEnum = {
  NONE: enumCount++,
  GLAD: enumCount++,
  SAD: enumCount++,
  MAD: enumCount++,
  LAUGHING: enumCount++,
  THINKING: enumCount++,
  PEACEFUL: enumCount++,
}

const initialState = {
  sectionKey: undefined,
  sectionKeys: undefined,
  sectionsCount: undefined,
  sectionsIndex: undefined,

  sectionView: sectionViewEnum.NONE,

  sectionStartId: undefined,
  sectionEndId: undefined,
  sectionCount: undefined,
  sectionCounter: undefined,
  sectionVersion: undefined,

  chunkStartId: undefined,
  chunkEndId: undefined,
  chunkCount: undefined,
  chunkCounter: undefined,
  chunkVerses: undefined,

  verseId: undefined,
  verseText: undefined,
  verseRef: undefined,
  verseCounter: undefined,
  verseHighlight: highlighterTypeEnum.NONE,

  deletedSectionKey: undefined,

  chaptersRead: 0,

  verseHighlights: undefined,
}

const reducer = (state, action) => {
  let nextState = {...state}

  // console.log("reducer - " + action.type)

  function determineSectionsCountAndIndex (sectionKey, sectionKeys) {
    let sectionKeysArr = sectionKeys.split(";").filter(k => k)
    let sectionsCount = sectionKeysArr.length
    let sectionsIndex = sectionKeysArr.indexOf(sectionKey)
    return [sectionsCount, sectionsIndex]
  }

  switch(action.type) {
    case 'init':
      nextState.sectionKey = action.data.sectionKey
      nextState.sectionKeys = action.data.sectionKeys
      nextState.verseHighlights = action.data.verseHighlights
      nextState.sectionView = action.data.sectionKey ? sectionViewEnum.VERSE : sectionViewEnum.SELECT
      
      if (action.data.sectionKeys) {
        [nextState.sectionsCount, nextState.sectionsIndex] =
          determineSectionsCountAndIndex(action.data.sectionKey, action.data.sectionKeys)
      }

      nextState.verseCounter = 0
      break
    case 'set-section-key':
      nextState.sectionKey = action.data.sectionKey
      nextState.sectionView = action.data.sectionKey ? sectionViewEnum.VERSE : sectionViewEnum.SELECT
      nextState.sectionVersion = action.data.version

      if (!state.sectionKeys || (action.data.sectionKey && state.sectionKeys.indexOf(action.data.sectionKey) < 0)) {
        nextState.sectionKeys = [state.sectionKeys, action.data.sectionKey].join(";")
      }
      
      [nextState.sectionsCount, nextState.sectionsIndex] =
        determineSectionsCountAndIndex(nextState.sectionKey, nextState.sectionKeys)

      if (nextState.sectionKey !== state.sectionKey) {
        nextState.sectionStartId = initialState.sectionStartId
        nextState.sectionEndId = initialState.sectionEndId
        nextState.sectionCount = initialState.sectionCount
        nextState.sectionCounter = initialState.sectionCounter

        nextState.chunkStartId = initialState.chunkStartId
        nextState.chunkEndId = initialState.chunkEndId
        nextState.chunkCount = initialState.chunkCount
        nextState.chunkCounter = initialState.chunkCounter
        nextState.chunkVerses = initialState.chunkVerses

        nextState.nextChunkStartId = initialState.nextChunkStartId
        nextState.nextChunkEndId = initialState.nextChunkEndId

        nextState.verseRef = initialState.verseRef
        nextState.verseText = initialState.verseText
      }
      break
    case 'init-section':
      let [sectionStartId, sectionEndId] = state.sectionKey.split("-")

      nextState.sectionStartId = sectionStartId
      nextState.sectionEndId = sectionEndId
      nextState.sectionCount = getVerseCount(sectionStartId, sectionEndId)
      nextState.sectionCounter = 1

      nextState.chunkVerses = []
      break
    case 'load-section':
      nextState.sectionStartId = action.data.section.startId
      nextState.sectionEndId = action.data.section.endId
      nextState.sectionCount = action.data.section.count
      nextState.sectionCounter = action.data.section.counter
      nextState.sectionVersion = action.data.section.version

      nextState.chunkStartId = action.data.section.chunk.startId
      nextState.chunkEndId = action.data.section.chunk.endId
      nextState.chunkCount = action.data.section.chunk.count
      nextState.chunkCounter = action.data.section.chunk.counter
      nextState.chunkVerses = action.data.section.chunk.verses
      break
    case 'init-chunk':
      let chunkStartVerseId = state.sectionStartId
      let chunkEndVerseId = getChunkEndVerseId(state.sectionStartId, state.sectionEndId)

      nextState.chunkStartId = chunkStartVerseId
      nextState.chunkEndId = chunkEndVerseId
      nextState.chunkCount = getVerseCount(chunkStartVerseId, chunkEndVerseId)
      nextState.chunkCounter = 1

      nextState.nextChunkStartId = chunkStartVerseId
      nextState.nextChunkEndId = chunkEndVerseId
      break
    case 'update-verses':
      nextState.chunkVerses = state.chunkVerses.slice(state.chunkCounter-1).concat(action.data.verses)
      nextState.chunkStartId = getRelativeVerseId(state.chunkStartId, state.chunkCounter-1)
      nextState.chunkEndId = state.nextChunkEndId
      nextState.chunkCount = getVerseCount(nextState.chunkStartId, state.nextChunkEndId)
      nextState.chunkCounter = 1
      break
    case 'set-verse':
      nextState.verseId = getRelativeVerseId(state.chunkStartId, state.chunkCounter-1)
      nextState.verseText = state.chunkVerses[state.chunkCounter-1]
      nextState.verseRef = getReferenceFromId(nextState.verseId)
      
      if (state.verseHighlights) {
        let {[nextState.verseId]: verseHighlight=highlighterTypeEnum.NONE} = state.verseHighlights
        nextState.verseHighlight = verseHighlight
      }
      break
    case 'increment':
      if ((state.chunkCount + 1) - state.chunkCounter <= VERSES_BEFORE_NEXT_CHUNK) {
        if (state.chunkEndId !== state.sectionEndId) {
          nextState.nextChunkStartId = getRelativeVerseId(state.chunkEndId, 1)
          nextState.nextChunkEndId = getChunkEndVerseId(nextState.nextChunkStartId, state.sectionEndId)
        }
      }

      if ((state.chunkCounter + 1) <= state.chunkCount) {
        nextState.chunkCounter += 1
      }

      if ((state.sectionCounter + 1) > state.sectionCount) {
        nextState.sectionView = sectionViewEnum.END
      }
      else {
        nextState.sectionCounter += 1
      }

      nextState.verseCounter += 1
      if (nextState.verseCounter === DEFAULT_CHAPTER_LENGTH) {
        nextState.chaptersRead += 1
      }
      else if (nextState.verseCounter > DEFAULT_CHAPTER_LENGTH) {
        nextState.verseCounter = 1
      }
      break
    case 'update-chunk':
      nextState.nextChunkStartId = action.data.startId
      nextState.nextChunkEndId = action.data.endId
      break
    case 'restart-section':
      if (state.sectionStartId !== state.chunkStartId) {
        nextState.chunkStartId = state.sectionStartId
        nextState.chunkEndId = getChunkEndVerseId(state.sectionStartId, state.sectionEndId)
        nextState.chunkCount = getVerseCount(nextState.chunkStartId, nextState.chunkEndId)
        nextState.chunkVerses = []

        nextState.nextChunkStartId = nextState.chunkStartId
        nextState.nextChunkEndId = nextState.chunkEndId
      }

      nextState.verseRef = initialState.verseRef
      nextState.verseText = initialState.verseText

      nextState.sectionCounter = 1
      nextState.chunkCounter = 1

      nextState.sectionView = sectionViewEnum.VERSE
      break
    case 'next-section':
      let nextSectionsIndex = state.sectionsIndex + 1
      if (nextSectionsIndex >= state.sectionsCount) {
        nextState.sectionView = sectionViewEnum.SELECT
      }
      else {
        nextState.sectionsIndex = nextSectionsIndex
        nextState.sectionKey = state.sectionKeys.split(";").filter(k => k)[nextSectionsIndex]
      }
      break
    case 'prev-section':
      if (state.sectionsIndex-1 >= 0) {
        let nextSectionsIndex = state.sectionsIndex-1
        nextState.sectionsIndex = nextSectionsIndex
        nextState.sectionKey = state.sectionKeys.split(";").filter(k => k)[nextSectionsIndex]
      }
      break
    case 'show-verse':
      nextState.sectionView = sectionViewEnum.VERSE
      break
    case 'show-select':
      nextState.sectionView = sectionViewEnum.SELECT
      break
    case 'show-delete':
      nextState.sectionView = sectionViewEnum.DELETE
      break
    case 'delete-section':
      nextState.deletedSectionKey = state.sectionKey
      nextState.sectionView = sectionViewEnum.NONE
      break
    case 'finish-delete':
      nextState.deletedSectionKey = initialState.deletedSectionKey

      let sectionKeysArr = state.sectionKeys.split(";").filter(k => k && k !== state.deletedSectionKey)
      nextState.sectionKeys = sectionKeysArr.length > 0 ? sectionKeysArr.join(";") : null
      nextState.sectionsCount = sectionKeysArr.length
      nextState.sectionsIndex = Math.min(state.sectionsIndex, nextState.sectionsCount-1)

      if (nextState.sectionsCount > 0) {
        nextState.sectionKey = sectionKeysArr[nextState.sectionsIndex]
        nextState.sectionView = sectionViewEnum.VERSE
      }
      else {
        nextState.sectionKey = null
        nextState.sectionView = sectionViewEnum.SELECT
      }
      break
    case 'show-menu':
      nextState.sectionView = sectionViewEnum.MENU
      break
    case 'set-highlight':
      nextState.verseHighlight = action.data
      if (action.data !== highlighterTypeEnum.NONE) {
        if (state.verseHighlights) {
          nextState.verseHighlights = {...state.verseHighlights, [state.verseId]: action.data}
        }
        else {
          nextState.verseHighlights = {[state.verseId]: action.data}
        }
      }
      else if (state.verseHighlights) {
        let removed
        ({[state.verseId]: removed, ...nextState.verseHighlights} = state.verseHighlights)
      }
      break
    default:
      throw new Error()
  }

  return nextState
}

export default function App() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const updateStoredSection = useCallback((sectionCounter, chunkCounter) => {
    localStorage.setItem(
      state.sectionKey,
      JSON.stringify({
        startId: state.sectionStartId,
        endId: state.sectionEndId,
        count: state.sectionCount,
        counter: sectionCounter,
        version: state.sectionVersion,
        chunk: {
          startId: state.chunkStartId,
          endId: state.chunkEndId,
          count: state.chunkCount,
          counter: chunkCounter,
          verses: state.chunkVerses,
        },
      })
    ) 
  }, [state.sectionKey, state.sectionStartId, state.sectionEndId, state.sectionCount,
      state.sectionVersion, state.chunkStartId, state.chunkEndId, state.chunkCount,
      state.chunkVerses])

  useEffect(() => {
    let sectionKey = localStorage.getItem("sectionKey")
    let sectionKeys = localStorage.getItem("sectionKeys")
    let verseHighlightsJSON = localStorage.getItem("verseHighlights")
    let verseHighlights = verseHighlightsJSON ? JSON.parse(verseHighlightsJSON) : null
    dispatch({type: 'init', data: {sectionKey: sectionKey, sectionKeys: sectionKeys, verseHighlights: verseHighlights}})
  }, [])

  useEffect(() => {
    if (state.sectionKey) {
      let sectionJson = localStorage.getItem(state.sectionKey)
      if (!sectionJson) {
        dispatch({type: 'init-section'})
      }
      else {
        let section = JSON.parse(sectionJson)
        dispatch({type: 'load-section', data: {section: section}})
      }

      localStorage.setItem("sectionKey", state.sectionKey)
    }
    else if (state.sectionKey === null) {
      localStorage.removeItem("sectionKey")
    }
  }, [state.sectionKey])

  useEffect(() => {
    if (state.sectionKeys) {
      localStorage.setItem("sectionKeys", state.sectionKeys)
    }
    else if (state.sectionKeys === null) {
      localStorage.removeItem("sectionKeys")
    }
  }, [state.sectionKeys])

  useEffect(() => {
    if (state.chunkVerses && state.chunkVerses.length === 0) {
      dispatch({type: 'init-chunk'})
    }
  }, [state.chunkVerses])

  useEffect(() => {
    if (state.nextChunkStartId) {
      fetchNextChunk(state.nextChunkStartId, state.nextChunkEndId, state.sectionVersion)
      .then(responseObj => {
        dispatch({type: 'update-verses', data: {verses: responseObj.verses}})
      })
    }
  }, [state.nextChunkStartId, state.nextChunkEndId, state.sectionVersion])

  useEffect(() => {
    if (state.sectionKey && state.chunkVerses && state.chunkVerses.length > 0) {
      dispatch({type: 'set-verse'})
      updateStoredSection(state.sectionCounter, state.chunkCounter)
    }
  }, [state.sectionKey, state.sectionCounter, state.chunkCounter, state.chunkVerses, updateStoredSection])

  useEffect(() => {
    if (state.deletedSectionKey) {
      localStorage.removeItem(state.deletedSectionKey)
      dispatch({type: 'finish-delete'})
    }
  }, [state.deletedSectionKey])

  useEffect(() => {
    if (state.verseHighlights) {
      localStorage.setItem("verseHighlights", JSON.stringify(state.verseHighlights))
    }
  }, [state.verseHighlight, state.verseHighlights])

  // console.log(state)

	return (
		<div className="fixed w-screen h-full p-4 bg-gainsboro">
			<div className="flex flex-col h-full max-w-[320px] mx-auto">
        <AppContext.Provider value={{state, dispatch}}>
          {state.sectionView === sectionViewEnum.SELECT &&
            <SectionSelectView/>
          }

          {state.sectionView === sectionViewEnum.VERSE &&
            <VerseView/>
          }

          {state.sectionView === sectionViewEnum.END &&
            <SectionCompleteView/>
          }

          {state.sectionView === sectionViewEnum.DELETE &&
            <SectionDeleteView/>
          }

          {state.sectionView === sectionViewEnum.MENU &&
            <MenuView/>
          }
        </AppContext.Provider>
      </div>
		</div>
	)
}

export {
  AppContext,
  sectionViewEnum,
  highlighterTypeEnum,
}