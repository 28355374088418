import { useContext, useReducer } from "react"

import { AppContext } from "../App"
import { getBookOptions, getChapterOptions, getEndVerse, joinVerseId } from "../Data"

const initialState = {
	startBook: 1,
	startChapter: 1,
	endBook: 1,
	endChapter: 1,
	version: "ESV",
}

const reducer = (state, action) => {
	let nextState = {...state}

	switch(action.type)
	{
		case 'set-start-book':
			nextState.startBook = Number(action.data)
			nextState.startChapter = 1
			if (nextState.startBook > nextState.endBook) {
				nextState.endBook = nextState.startBook
				nextState.endChapter = 1
			}
			break
		case 'set-start-chapter':
			nextState.startChapter = Number(action.data)
			if (nextState.startBook === nextState.endBook && nextState.startChapter > nextState.endChapter) {
				nextState.endChapter = nextState.startChapter
			}
			break
		case 'set-end-book':
			nextState.endBook = Number(action.data)
			nextState.endChapter = 1
			break
		case 'set-end-chapter':
			nextState.endChapter = Number(action.data)
			break
		case 'set-version':
			nextState.version = action.data
			break
		default:
			throw new Error()
	}

	return nextState
}

export default function SectionSelectCard (props) {
	const [state, dispatch] = useReducer(reducer, initialState)
	const {dispatch: appDispatch} = useContext(AppContext)

	function onClickSubmit() {
		let startVerseId = joinVerseId(state.startBook, state.startChapter, 1)
		let endVerseId = joinVerseId(state.endBook, state.endChapter, getEndVerse(state.endBook, state.endChapter))	
		appDispatch({
			type: 'set-section-key',
			data: {
				sectionKey: [startVerseId, endVerseId].join("-"),
				version: state.version
			}
		})
	}

	function onClickCancel () {
		appDispatch({type: 'show-verse'})
	}

	const versionsArr = [
		{value: "ESV", label: "English Standard Version (ESV)"},
		// {value: "NLT", label: "New Living Translation (NLT)"},
		// {value: "NIV", label: "New International Version (NIV)"},
		// {value: "KJV", label: "King James Version (KJV)"},
		// {value: "NAS", label: "New American Standard Bible (NASB)"},
	]

	return (<>
		<div className="rounded-lg bg-whitesmoke shadow-slant shadow-midgray px-4 py-6">
			<div>
				<p
					className="leading-tight text-center text-3xl font-bold">
					Read the Bible
				</p>
				<p
					className="leading-tight text-center font-marker">
					one verse at a time
				</p>
			</div>

			<fieldset className="flex justify-between border border-black rounded-lg mt-3 pt-1 pb-2">
				<legend className="ml-3 px-1 leading-none text-sm font-semibold">
					From
				</legend>

				<select
					className="grow mr-1 px-1 rounded-none"
					value={String(state.startBook).padStart(2, "0")}
					onChange={e => dispatch({type: 'set-start-book', data: e.target.value})}>
					{getBookOptions().map(option => {
						return <option key={option.value} value={option.value}>{option.label}</option>
					})}
				</select>

				<select
					className="rounded-none"
					value={String(state.startChapter).padStart(3, "0")}
					onChange={e => dispatch({type: 'set-start-chapter', data: e.target.value})}>
					{getChapterOptions(state.startBook).map(option => {
						return <option key={option.value} value={option.value}>{option.label}</option>
					})}
				</select>
			</fieldset>

			<fieldset className="flex justify-between border border-black rounded-lg mt-3 pt-1 pb-2">
				<legend className="ml-3 px-1 leading-none text-sm font-semibold">
					To
				</legend>

				<select
					className="grow mr-1 px-1 rounded-none"
					value={String(state.endBook).padStart(2, "0")}
					onChange={e => dispatch({type: 'set-end-book', data: e.target.value})}>
					{getBookOptions(state.startBook).map(option => {
						return <option key={option.value} value={option.value}>{option.label}</option>
					})}
				</select>

				<select
					className="rounded-none"
					value={String(state.endChapter).padStart(3, "0")}
					onChange={e => dispatch({type: 'set-end-chapter', data: e.target.value})}>
					{getChapterOptions(state.endBook).map(option => {
						return <option key={option.value} value={option.value}>{option.label}</option>
					})}
				</select>
			</fieldset>

			<div className="border border-black rounded-lg mt-5 pt-1 pb-2">
				<div className="flex ml-3 -mt-4">
					<p className="inline px-1 bg-whitesmoke text-sm font-semibold">
						In
					</p>
				</div>

				<select
					className="w-full px-1 text-ellipsis rounded-none"
					value={state.version}
					onChange={e => dispatch({type: 'set-version', data: e.target.value})}>
					{versionsArr.map(option => {
						return (
							<option
								key={option.value}
								value={option.value}>
								{option.label}
							</option>
						)
					})}
				</select>
			</div>
		</div>

		<div
			className="flex justify-around mt-6">
			{props.canCancel &&
				<button
					className="block bg-whitesmoke shadow-slant shadow-midgray px-4 py-2 rounded-lg font-bold"
					onClick={onClickCancel}>
					Cancel
				</button>
			}
			<button
				className="block bg-whitesmoke shadow-slant shadow-green-500 px-4 py-2 rounded-lg font-bold"
				onClick={onClickSubmit}>
				Read
			</button>
		</div>
	</>)
}