import { useContext, useState } from "react"
import { AppContext } from "../App"
import { ReactComponent as MediumLogoSvg } from '../img/27VERSES.svg'

export default function MenuView () {
	const {dispatch: appDispatch} = useContext(AppContext)
	const [bShowAbout, setShowAbout] = useState()
	const [bShowHowTo, setShowHowTo] = useState()
	const [bShowCopyright, setShowCopyright] = useState()

	function onClickAbout () {
		setShowAbout(!bShowAbout)
	}

	function onClickHowTo () {
		setShowHowTo(!bShowHowTo)
	}

	function onClickCopyright () {
		setShowCopyright(!bShowCopyright)
	}

	function onClickClose () {
		appDispatch({type: 'show-verse'})
	}

	return (<>
		<div className="flex-grow"/>

		<div className="rounded-lg p-3 pt-0 overflow-y-auto bg-whitesmoke shadow-slant shadow-midgray">
			<div>
				<div
					className={`sticky top-0 pt-3 border-b-2 border-black bg-whitesmoke font-bold font-sans text-lg`}
					onClick={onClickAbout}>
					<p>
						About
					</p>
				</div>

				{bShowAbout &&
					<p className="pt-3">
						<MediumLogoSvg className="inline text-sm align-baseline" height="1em" /> is a bite-sized Bible-reading app. 
						Read the Bible, one verse at a time.
					</p>
				}
			</div>

			<div>
				<div
					className={`sticky top-0 pt-3 border-b-2 border-black bg-whitesmoke font-bold font-sans text-lg`}
					onClick={onClickHowTo}>
					<p>
						How to Use
					</p>
				</div>

				{bShowHowTo &&
					<p className="pt-3">
						Swipe up on a verse to show the next verse.<br/><br/>
						Swipe left and right on a verse to move between your bookmarks (or to add a new bookmark).<br/><br/>
						Swipe down on a verse to delete the current bookmark.
					</p>
				}
			</div>

			<div>
				<div
					className={`sticky top-0 pt-3 border-b-2 border-black bg-whitesmoke font-bold font-sans text-lg`}
					onClick={onClickCopyright}>
					<p>
						Copyright
					</p>
				</div>

				{bShowCopyright &&
					<div className="pt-3">
						<a className="font-marker text-xl text-blue-500 underline" href="https://www.esv.org" target="_blank" rel="noopener noreferrer">ESV</a>
						<p>
							Scripture quotations marked “ESV” are from the ESV® Bible (The Holy Bible, English Standard Version®), copyright © 2001 by Crossway, a publishing ministry of Good News Publishers. Used by permission. All rights reserved. The ESV text may not be quoted in any publication made available to the public by a Creative Commons license. The ESV may not be translated into any other language.<br/><br/>
							Users may not copy or download more than 500 verses of the ESV Bible or more than one half of any book of the ESV Bible.
						</p>
					</div>
				}
			</div>
		</div>

		<div className="mt-6">
			<button
				className="mx-auto block bg-whitesmoke shadow-slant shadow-green-500 px-4 py-2 rounded-lg font-bold"
				onClick={onClickClose}>
				Close
			</button>
		</div>

		<div className="flex-grow"/>
	</>)
}