import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { AppContext, highlighterTypeEnum } from "../App"

const swipeType = {
	SWIPE_NONE: 0,
	SWIPE_UP: 1,
	SWIPE_DOWN: 2,
	SWIPE_LEFT: 3,
	SWIPE_RIGHT: 4,
}

const initialSwipeState = {
	startTime: undefined,
	startPos: undefined,
	type: swipeType.SWIPE_NONE,
	endTime: undefined,
}

export default function VerseCard (props) {
	const {state: appState} = useContext(AppContext)
	const [swipeState, setSwipeState] = useState(initialSwipeState)
	
	const cardRef = useRef()

	const onSwipeTransitionEnd = useCallback((e) => {
		cardRef.current.removeEventListener("transitionend", onSwipeTransitionEnd, true)
		
		switch (swipeState.type) {
			case swipeType.SWIPE_NONE:
				break
			case swipeType.SWIPE_UP:
				props.onSwipeUp()
				break
			case swipeType.SWIPE_DOWN:
				props.onSwipeDown()
				break
			case swipeType.SWIPE_LEFT:
				props.onSwipeLeft()
				break
			case swipeType.SWIPE_RIGHT:
				props.onSwipeRight()
				break
			default:
				throw new Error()	
		}

		setSwipeState(initialSwipeState)
	}, [swipeState.type, props])

	useEffect(() => {
		// ten-ms fudge factor to make sure transition always plays?
		if (appState.verseText) {
			setTimeout(() => {
				cardRef.current.style.transitionProperty = "opacity"
				cardRef.current.style.transform = "translate(0, 0)"
				cardRef.current.style.opacity = "1"
				cardRef.current.style.transitionDuration = "150ms"
				cardRef.current.style.transitionTimingFunction = "ease-out"
			}, 10)
		}
	}, [appState.verseText])

	useEffect(() => {
		if (swipeState.endTime) {
			const swipeAnimDist = 40
			
			let transform = undefined
			let opacity = 0

			switch (swipeState.type) {
				case swipeType.SWIPE_UP:
					transform = `translate(0, -${swipeAnimDist}px)`
					break
				case swipeType.SWIPE_RIGHT:
					transform = `translate(${swipeAnimDist}px, 0)`
					break
				case swipeType.SWIPE_DOWN:
					transform = `translate(0, ${swipeAnimDist}px)`
					break
				case swipeType.SWIPE_LEFT:
					transform = `translate(-${swipeAnimDist}px, 0)`
					break
				default:
					transform = `translate(0, 0)`
					opacity = 1
					break
			}

			cardRef.current.style.transitionProperty = "transform, opacity"
			cardRef.current.style.transform = transform
			cardRef.current.style.opacity = opacity
			cardRef.current.style.transitionDuration = "300ms"
			cardRef.current.style.transitionTimingFunction = "ease-out"

			cardRef.current.addEventListener("transitionend", onSwipeTransitionEnd, true)
		}
	}, [swipeState.type, swipeState.endTime, onSwipeTransitionEnd])

	function onSwipeStart (pos) {
		if (!swipeState.startTime) {
			setSwipeState({...swipeState, startTime: Date.now(), startPos: pos})
			cardRef.current.style.transitionDuration = ""
		}
	}

	function onSwipeContinue (pos) {
		if (swipeState.startTime && !swipeState.endTime) {
			const swipeX = pos.x - swipeState.startPos.x
			const swipeY = swipeState.startPos.y - pos.y

			const absSwipeX = Math.abs(swipeX)
			const absSwipeY = Math.abs(swipeY)

			if (!swipeState.type) {
				const card = cardRef.current
				const cardHasScroll = card.scrollHeight > card.clientHeight
				const cardHasScrollDown = cardHasScroll && ((card.scrollTop + card.clientHeight) < card.scrollHeight)
				const cardHasScrollUp = cardHasScroll && card.scrollTop > 0

				const minSwipe = 10
				if (absSwipeX >= minSwipe || absSwipeY >= minSwipe)
				{
					const swipeA = Math.atan2(swipeY, swipeX) * (180 / Math.PI)
					if (Math.abs(swipeA) <= 30 && props.onSwipeRight) {
						setSwipeState({...swipeState, type: swipeType.SWIPE_RIGHT})
					}
					else if (swipeA > 30 && swipeA < 150 && props.onSwipeUp) {
						if (cardHasScrollDown) {
							setSwipeState(initialSwipeState)
						}
						else {
							setSwipeState({...swipeState, type: swipeType.SWIPE_UP})
						}
					}
					else if (Math.abs(swipeA) >= 150 && props.onSwipeLeft) {
						setSwipeState({...swipeState, type: swipeType.SWIPE_LEFT})
					}
					else if (swipeA < -30 && swipeA > -150 && props.onSwipeDown) {
						if (cardHasScrollUp) {
							setSwipeState(initialSwipeState)
						}
						else {
							setSwipeState({...swipeState, type: swipeType.SWIPE_DOWN})
						}
					}
				}
			}
			else {
				const swipeRange = {min: 40, max: 200}
				if ((swipeState.type === swipeType.SWIPE_LEFT && swipeX <= 0) ||
					  (swipeState.type === swipeType.SWIPE_RIGHT && swipeX >= 0)) {
					const hiT = Math.min(1.0, absSwipeX / swipeRange.min)
					const loT = Math.min(1.0, absSwipeX / swipeRange.max)
					const hiX = hiT * swipeRange.min * Math.sign(swipeX)
					const loX = loT * swipeRange.min * Math.sign(swipeX)
					const x = loX + loT * (hiX - loX)
					cardRef.current.style.transform = `translate(${x}px, 0)`
				}
				else if (swipeState.type === swipeType.SWIPE_UP || swipeState.type === swipeType.SWIPE_DOWN) {
					const hiT = Math.min(1.0, absSwipeY / swipeRange.min)
					const loT = Math.min(1.0, absSwipeY / swipeRange.max)
					const hiY = hiT * swipeRange.min * Math.sign(-swipeY)
					const loY = loT * swipeRange.min * Math.sign(-swipeY)
					const y = loY + loT * (hiY - loY)
					cardRef.current.style.transform = `translate(0, ${y}px)`
				}
			}
		}
	}

	function onSwipeEnd () {
		if (!swipeState.type) {
			setSwipeState(initialSwipeState)
			return
		}

		const swipeTime = Date.now() - swipeState.startTime
		const maxSwipeTime = 1000
		if (swipeTime <= maxSwipeTime) {
			setSwipeState({...swipeState, endTime: Date.now()})
		}
		else {
			setSwipeState({...swipeState, endTime: Date.now(), type: swipeType.SWIPE_NONE})
		}
	}

	function onTouchEvent (e) {
		e.preventDefault()

		switch (e.type) {
			case "touchstart":
				onSwipeStart({x: e.touches[0].clientX, y: e.touches[0].clientY})
				break
			case "touchmove":
				onSwipeContinue({x: e.touches[0].clientX, y: e.touches[0].clientY})
				break
			case "touchend":
				onSwipeEnd()
				break
			default:
				break
		}
	}

	let highlightColor = ""
	switch (appState.verseHighlight) {
		case highlighterTypeEnum.GLAD:
			highlightColor = "bg-yellow-300"
			break
		case highlighterTypeEnum.SAD:
			highlightColor = "bg-blue-300"
			break
		case highlighterTypeEnum.MAD:
			highlightColor = "bg-red-300"
			break
		case highlighterTypeEnum.LAUGHING:
			highlightColor = "bg-orange-300"
			break
		case highlighterTypeEnum.THINKING:
			highlightColor = "bg-purple-300"
			break
		case highlighterTypeEnum.PEACEFUL:
			highlightColor = "bg-green-300"
			break
		default:
			highlightColor = ""
	}

	return (
		<div
			ref={cardRef}
			className="rounded-lg p-3 pt-0 overflow-y-auto opacity-0 bg-whitesmoke shadow-slant shadow-midgray"
			// onClick={props.onSwipeUp}
			onMouseDown={e => onSwipeStart({x: e.clientX, y: e.clientY})}
			onMouseMove={e => onSwipeContinue({x: e.clientX, y: e.clientY})}
			onMouseUp={onSwipeEnd}
			onTouchStart={onTouchEvent}
			onTouchMove={onTouchEvent}
			onTouchEnd={onTouchEvent}>
			<div
				className={`sticky top-0 flex justify-between border-b-2 border-black bg-whitesmoke font-bold font-sans
								text-lg pt-3`}>
				<p>{appState.verseRef}</p>
				<p>{appState.sectionVersion}</p>
			</div>
			
			<p className="mt-2 text-left text-lg text-midgray-600 leading-snug font-times select-none whitespace-pre-line">
				<span className={`${highlightColor}`}>{appState.verseText}</span>
			</p>
		</div>
	)
}