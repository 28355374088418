import { useContext } from "react"
import { AppContext } from "../App"

export default function SectionDeleteCard ({startRef, endRef}) {
	const {dispatch: appDispatch} = useContext(AppContext)

	function onClickDelete () {
		appDispatch({type: 'delete-section'})
	}

	function onClickCancel () {
		appDispatch({type: 'show-verse'})
	}

	return (<>
		<div className="rounded-lg bg-whitesmoke shadow-slant shadow-midgray px-4 py-6">
			<div>
				<p
					className="leading-tight text-center text-3xl font-bold">
					Delete Section?
				</p>
				<p
					className="leading-tight text-center font-marker">
					{startRef} - {endRef}
				</p>
			</div>
		</div>

		<div className="flex justify-around mt-6">
			<button
				className="block bg-whitesmoke shadow-slant shadow-midgray px-4 py-2 rounded-lg font-bold"
				onClick={onClickCancel}>
				Cancel
			</button>
			<button
				className="block bg-whitesmoke shadow-slant shadow-red-500 px-4 py-2 rounded-lg font-bold"
				onClick={onClickDelete}>
				Delete
			</button>
		</div>
	</>)
}