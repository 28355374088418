import { useContext } from "react";
import { AppContext } from "../App";
import SectionSelectCard from "../components/SectionSelectCard";

export default function SectionSelectView () {
	const {state: appState} = useContext(AppContext)

	return (<>
		<div className="flex-grow"/>
			<SectionSelectCard canCancel={appState.sectionsCount > 0}/>
		<div className="flex-grow"/>
	</>)
}