const DEFAULT_CHAPTER_LENGTH = 27
const DEFAULT_CACHE_LENGTH = DEFAULT_CHAPTER_LENGTH * 3
const VERSES_BEFORE_NEXT_CHUNK = 5

const BOOKS = [

	{
		name: null,
		id: null,
		chapters: null
	},

	// Old Testament
	{
		name: "Genesis",
		shortName: "Gen.",
		id: "01",
		chapters: [null, 31,25,24,26,32,22,24,22,29,32,32,20,18,24,21,16,27,33,38,18,34,24,20,67,34,35,46,22,35,43,55,32,20,31,
					  29,43,36,30,23,23,57,38,34,34,28,34,31,22,33,26],
	},
	{
		name: "Exodus",
		shortName: "Exo.",
		id: "02",
		chapters: [null, 22,25,22,31,23,30,25,32,35,29,10,51,22,31,27,36,16,27,25,26,36,31,33,18,40,37,21,43,46,38,18,35,23,35,
					  35,38,29,31,43,38],
	},
	{
		name: "Levicticus",
		shortName: "Lev.",
		id: "03",
		chapters: [null, 17,16,17,35,19,30,38,36,24,20,47,8,59,57,33,34,16,30,37,27,24,33,44,23,55,46,34],
	},
	{
		name: "Numbers",
		shortName: "Num.",
		id: "04",
		chapters: [null, 54,34,51,49,31,27,89,26,23,36,35,16,33,45,41,50,13,32,22,29,35,41,30,25,18,65,23,31,40,16,54,42,56,29,
					  34,13],
	},
	{
		name: "Deuteronomy",
		shortName: "Deut.",
		id: "05",
		chapters: [null, 46,37,29,49,33,25,26,20,29,22,32,32,18,29,23,22,20,22,21,20,23,30,25,22,19,19,26,68,29,20,30,52,29,12],
	},
	{
		name: "Joshua",
		shortName: "Josh.",
		id: "06",
		chapters: [null, 18,24,17,24,15,27,26,35,27,43,23,24,33,15,63,10,18,28,51,9,45,34,16,33],
	},
	{
		name: "Judges",
		shortName: "Judg.",
		id: "07",
		chapters: [null, 36,23,31,24,31,40,25,35,57,18,40,15,25,20,20,31,13,31,30,48,25],
	},
	{
		name: "Ruth",
		shortName: "Ruth",
		id: "08",
		chapters: [null, 22,23,18,22],
	},
	{
		name: "1 Samuel",
		shortName: "1 Sam.",
		id: "09",
		chapters: [null, 28,36,21,22,12,21,17,22,27,27,15,25,23,52,35,23,58,30,24,42,15,23,29,22,44,25,12,25,11,31,13],
	},
	{
		name: "2 Samuel",
		shortName: "2 Sam.",
		id: "10",
		chapters: [null, 27,32,39,12,25,23,29,18,13,19,27,31,39,33,37,23,29,33,43,26,22,51,39,25],
	},
	{
		name: "1 Kings",
		shortName: "1 Kings",
		id: "11",
		chapters: [null, 53,46,28,34,18,38,51,66,28,29,43,33,34,31,34,34,24,46,21,43,29,53],
	},
	{
		name: "2 Kings",
		shortName: "2 Kings",
		id: "12",
		chapters: [null, 18,25,27,44,27,33,20,29,37,36,21,21,25,29,38,20,41,37,37,21,26,20,37,20,30],
	},
	{
		name: "1 Chronicles",
		shortName: "1 Chr.",
		id: "13",
		chapters: [null, 54,55,24,43,26,81,40,40,44,14,47,40,14,17,29,43,27,17,19,8,30,19,32,31,31,32,34,21,30],
	},
	{
		name: "2 Chronicles",
		shortName: "2 Chr.",
		id: "14",
		chapters: [null, 17,18,17,22,14,42,22,18,31,19,23,16,22,15,19,14,19,34,11,37,20,12,21,27,28,23,9,27,36,27,21,33,25,33,
					  27,23],
	},
	{
		name: "Ezra",
		shortName: "Ezra",
		id: "15",
		chapters: [null, 11,70,13,24,17,22,28,36,15,44],
	},
	{
		name: "Nehemiah",
		shortName: "Neh.",
		id: "16",
		chapters: [null, 11,20,32,23,19,19,73,18,38,39,36,47,31],
	},
	{
		name: "Esther",
		shortName: "Est.",
		id: "17",
		chapters: [null, 22,23,15,17,14,14,10,17,32,3],
	},
	{
		name: "Job",
		shortName: "Job",
		id: "18",
		chapters: [null, 22,13,26,21,27,30,21,22,35,22,20,25,28,22,35,22,16,21,29,29,34,30,17,25,6,14,23,28,25,31,40,22,33,37,
					  16,33,24,41,30,24,34,17],
	},
	{
		name: "Psalms",
		shortName: "Ps.",
		id: "19",
		chapters: [null, 6,12,8,8,12,10,17,9,20,18,7,8,6,7,5,11,15,50,14,9,13,31,6,10,22,12,14,9,11,12,24,11,22,22,28,12,40,22,
					  13,17,13,11,5,26,17,11,9,14,20,23,19,9,6,7,23,13,11,11,17,12,8,12,11,10,13,20,7,35,36,5,24,20,28,23,10,
					  12,20,72,13,19,16,8,18,12,13,17,7,18,52,17,16,15,5,23,11,13,12,9,9,5,8,28,22,35,45,48,43,13,31,7,10,10,
					  9,8,18,19,2,29,176,7,8,9,4,8,5,6,5,6,8,8,3,18,3,3,21,26,9,8,24,13,10,7,12,15,21,10,20,14,9,6],
	},
	{
		name: "Proverbs",
		shortName: "Prov.",
		id: "20",
		chapters: [null, 33,22,35,27,23,35,27,36,18,32,31,28,25,35,33,33,28,24,29,30,31,29,35,34,28,28,27,28,27,33,31],
	},
	{
		name: "Ecclesiastes",
		shortName: "Eccles.",
		id: "21",
		chapters: [null, 18,26,22,16,20,12,29,17,18,20,10,14],
	},
	{
		name: "Song of Solomon",
		shortName: "Song",
		id: "22",
		chapters: [null, 17,17,11,16,16,13,13,14],
	},
	{
		name: "Isaiah",
		shortName: "Isa.",
		id: "23",
		chapters: [null, 31,22,26,6,30,13,25,22,21,34,16,6,22,32,9,14,14,7,25,6,17,25,18,23,12,21,13,29,24,33,9,20,24,17,10,22,
					  38,22,8,31,29,25,28,28,25,13,15,22,26,11,23,15,12,17,13,12,21,14,21,22,11,12,19,12,25,24],
	},
	{
		name: "Jeremiah",
		shortName: "Jer.",
		id: "24",
		chapters: [null, 19,37,25,31,31,30,34,22,26,25,23,17,27,22,21,21,27,23,15,18,14,30,40,10,38,24,22,17,32,24,40,44,26,22,
					  19,32,21,28,18,16,18,22,13,30,5,28,7,47,39,46,64,34],
	},
	{
		name: "Lamentations",
		shortName: "Lam.",
		id: "25",
		chapters: [null, 22,22,66,22,22],
	},
	{
		name: "Ezekiel",
		shortName: "Ezek.",
		id: "26",
		chapters: [null, 28,10,27,17,17,14,27,18,11,22,25,28,23,23,8,63,24,32,14,49,32,31,49,27,17,21,36,26,21,26,18,32,33,31,
					  15,38,28,23,29,49,26,20,27,31,25,24,23,35],
	},
	{
		name: "Daniel",
		shortName: "Dan.",
		id: "27",
		chapters: [null, 21,49,30,37,31,28,28,27,27,21,45,13],
	},
	{
		name: "Hosea",
		shortName: "Hos.",
		id: "28",
		chapters: [null, 11,23,5,19,15,11,16,14,17,15,12,14,16,9],
	},
	{
		name: "Joel",
		shortName: "Joel",
		id: "29",
		chapters: [null, 20,32,21],
	},
	{
		name: "Amos",
		shortName: "Amos",
		id: "30",
		chapters: [null, 15,16,15,13,27,14,17,14,15],
	},
	{
		name: "Obadiah",
		shortName: "Obad.",
		id: "31",
		chapters: [null, 21],
	},
	{
		name: "Jonah",
		shortName: "Jonah",
		id: "32",
		chapters: [null, 17,10,10,11],
	},
	{
		name: "Micah",
		shortName: "Mic.",
		id: "33",
		chapters: [null, 16,13,12,13,15,16,20],
	},
	{
		name: "Nahum",
		shortName: "Nah.",
		id: "34",
		chapters: [null, 15,13,19],
	},
	{
		name: "Habakkuk",
		shortName: "Hab.",
		id: "35",
		chapters: [null, 17,20,19],
	},
	{
		name: "Zephaniah",
		shortName: "Zeph.",
		id: "36",
		chapters: [null, 18,15,20],
	},
	{
		name: "Haggai",
		shortName: "Hag.",
		id: "37",
		chapters: [null, 15,23],
	},
	{
		name: "Zechariah",
		shortName: "Zech.",
		id: "38",
		chapters: [null, 21,13,10,14,11,15,14,23,17,12,17,14,9,21],
	},
	{
		name: "Malachi",
		shortName: "Mal.",
		id: "39",
		chapters: [null, 14,17,18,6],
	},

	// New Testament

	{
		name: "Matthew",
		shortName: "Matt.",
		id: "40",
		chapters: [null, 25,23,17,25,48,34,29,34,38,42,30,50,58,36,39,28,27,35,30,34,46,46,39,51,46,75,66,20],
	},
	{
		name: "Mark",
		shortName: "Mark",
		id: "41",
		chapters: [null, 45,28,35,41,43,56,37,38,50,52,33,44,37,72,47,20],
	},
	{
		name: "Luke",
		shortName: "Luke",
		id: "42",
		chapters: [null, 80,52,38,44,39,49,50,56,62,42,54,59,35,35,32,31,37,43,48,47,38,71,56,53],
	},
	{
		name: "John",
		shortName: "John",
		id: "43",
		chapters: [null, 51,25,36,54,47,71,53,59,41,42,57,50,38,31,27,33,26,40,42,31,25],
	},
	{
		name: "Acts",
		shortName: "Acts",
		id: "44",
		chapters: [null, 26,47,26,37,42,15,60,40,43,48,30,25,52,28,41,40,34,28,41,38,40,30,35,27,27,32,44,31],
	},
	{
		name: "Romans",
		shortName: "Rom.",
		id: "45",
		chapters: [null, 32,29,31,25,21,23,25,39,33,21,36,21,14,26,33,25],
	},
	{
		name: "1 Corinthians",
		shortName: "1 Cor.",
		id: "46",
		chapters: [null, 31,16,23,21,13,20,40,13,27,33,34,31,13,40,58,24],
	},
	{
		name: "2 Corinthians",
		shortName: "2 Cor.",
		id: "47",
		chapters: [null, 24,17,18,18,21,18,16,24,15,18,33,21,14],
	},
	{
		name: "Galatians",
		shortName: "Gal.",
		id: "48",
		chapters: [null, 24,21,29,31,26,18],
	},
	{
		name: "Ephesians",
		shortName: "Eph.",
		id: "49",
		chapters: [null, 23,22,21,32,33,24],
	},
	{
		name: "Philippians",
		shortName: "Phil.",
		id: "50",
		chapters: [null, 30,30,21,23],
	},
	{
		name: "Colossians",
		shortName: "Col.",
		id: "51",
		chapters: [null, 29,23,25,18],
	},
	{
		name: "1 Thessalonians",
		shortName: "1 Thess.",
		id: "52",
		chapters: [null, 10,20,13,18,28],
	},
	{
		name: "2 Thessalonians",
		shortName: "2 Thess.",
		id: "53",
		chapters: [null, 12,17,18],
	},
	{
		name: "1 Timothy",
		shortName: "1 Tim.",
		id: "54",
		chapters: [null, 20,15,16,16,25,21],
	},
	{
		name: "2 Timothy",
		shortName: "2 Tim.",
		id: "55",
		chapters: [null, 18,26,17,22],
	},
	{
		name: "Titus",
		shortName: "Titus",
		id: "56",
		chapters: [null, 16,15,15],
	},
	{
		name: "Philemon",
		shortName: "Philem.",
		id: "57",
		chapters: [null, 25],
	},
	{
		name: "Hebrews",
		shortName: "Heb.",
		id: "58",
		chapters: [null, 14,18,19,16,14,20,28,13,28,39,40,29,25],
	},
	{
		name: "James",
		shortName: "James",
		id: "59",
		chapters: [null, 27,26,18,17,20],
	},
	{
		name: "1 Peter",
		shortName: "1 Pet.",
		id: "60",
		chapters: [null, 25,25,22,19,14],
	},
	{
		name: "2 Peter",
		shortName: "2 Pet.",
		id: "61",
		chapters: [null, 21,22,18],
	},
	{
		name: "1 John",
		shortName: "1 John",
		id: "62",
		chapters: [null, 10,29,24,21,21],
	},
	{
		name: "2 John",
		shortName: "1 John",
		id: "63",
		chapters: [null, 13],
	},
	{
		name: "3 John",
		shortName: "3 John",
		id: "64",
		chapters: [null, 14],
	},
	{
		name: "Jude",
		shortName: "Jude",
		id: "65",
		chapters: [null, 25],
	},
	{
		name: "Revelation",
		shortName: "Rev.",
		id: "66",
		chapters: [null, 20,29,22,11,14,17,17,13,21,11,19,17,18,20,8,21,18,24,21,15,27,21],
	},
]

function getBookOptions(startBook = 1) {
	let options = []
	for (let i = startBook; i < BOOKS.length; ++i) {
		options.push({value: BOOKS[i].id, label: BOOKS[i].name})
	}

	return options
}

function getChapterOptions(book, startChapter = 1) {
	let options = []
	for (let i = startChapter; i < BOOKS[book].chapters.length; ++i) {
		options.push({value: String(i).padStart(3, "0"), label: String(i)})
	}

	return options
}

function getEndVerse(book, chapter) {
	return BOOKS[book].chapters[chapter]
}

function sliceVerseId (verseId) {
	// returns book, chapter, verse
	return [Number(verseId.slice(0, 2)), Number(verseId.slice(2, 5)), Number(verseId.slice(5))]
}

function joinVerseId (book, chapter, verse) {
	return [String(book).padStart(2, "0"), String(chapter).padStart(3, "0"), String(verse).padStart(3, "0")].join("")
}

function getNextVerseId (verseId) {
	let [book, chapter, verse] = sliceVerseId(verseId)

	verse++
	if (verse >= BOOKS[book].chapters[chapter]) {
		chapter++
		verse = 0
		if (chapter >= BOOKS[book].chapters.length-1) {
			book++
			chapter = 0
			if (book >= BOOKS.length-1) {
				book = 0
			}
		}
	}

	return joinVerseId(book, chapter, verse)
}

function getRelativeVerseId(verseId, dist) {
	let [book, chapter, verse] = sliceVerseId(verseId)

	while (verse + dist > BOOKS[book].chapters[chapter]) {
		dist -= BOOKS[book].chapters[chapter] - verse
		chapter++
		verse = 0
		if (chapter > BOOKS[book].chapters.length-1) {
			book++
			chapter = 1
			if (book > BOOKS.length-1) {
				book = 1
			}
		}
	}

	verse += dist

	return joinVerseId(book, chapter, verse)
}

function getVerseCount(fromVerseId, toVerseId) {
	let reverse = false
	if (Number(toVerseId) < Number(fromVerseId)) {
		let tempVerseId = toVerseId
		toVerseId = fromVerseId
		fromVerseId = tempVerseId
		reverse = true
	}
	
	let [fromBook, fromChapter, fromVerse] = sliceVerseId(fromVerseId)
	let [toBook, toChapter, toVerse] = sliceVerseId(toVerseId)

	let count = 0
	while (fromBook < toBook) {
		while (fromChapter < BOOKS[fromBook].chapters.length) {
			count += BOOKS[fromBook].chapters[fromChapter] - fromVerse
			fromChapter++
			fromVerse = 0
		}
		fromBook++
		fromChapter = 0
	}

	while (fromChapter < toChapter) {
		count += BOOKS[fromBook].chapters[fromChapter] - fromVerse
		fromChapter++
		fromVerse = 0
	}

	count += toVerse - fromVerse + 1

	return reverse ? -count : count
}

function getChunkEndVerseId (cacheStartVerseId, sectionEndVerseId, cacheSize = DEFAULT_CACHE_LENGTH) {
	let cacheEndVerseId = getRelativeVerseId(cacheStartVerseId, cacheSize-1)
	let sectionEndDiff = getVerseCount(cacheEndVerseId, sectionEndVerseId)
	if (sectionEndDiff <= DEFAULT_CHAPTER_LENGTH) {
		cacheEndVerseId = sectionEndVerseId
	}

	return cacheEndVerseId
}

function getReferenceFromId (verseId) {
	let [book, chapter, verse] = sliceVerseId(verseId)
	return `${BOOKS[book].name} ${chapter}:${verse}` 
}

function getShortReferenceFromId (verseId) {
	let [book, chapter, verse] = sliceVerseId(verseId)
	return `${BOOKS[book].shortName} ${chapter}:${verse}` 
}

function fetchNextChunk (chunkStartVerseId, chunkEndVerseId, version) {
	return fetch(`/api/passages/${chunkStartVerseId}-${chunkEndVerseId}?v=${version}`, {method: "GET"})
	.then(response => {
	  return response.text()
	})
	.then(responseJson => {
	  return JSON.parse(responseJson)
	})
 }

export {
	getBookOptions,
	getChapterOptions,
	getEndVerse,
	sliceVerseId,
	joinVerseId,
	getNextVerseId,
	getRelativeVerseId,
	getVerseCount,
	getChunkEndVerseId,
	getReferenceFromId,
	getShortReferenceFromId,
	fetchNextChunk,

	DEFAULT_CHAPTER_LENGTH,
	DEFAULT_CACHE_LENGTH,
	VERSES_BEFORE_NEXT_CHUNK,
}