import { useContext } from 'react'
import { AppContext } from '../App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import VerseCard from '../components/VerseCard'
import { ReactComponent as LoadingSvg } from '../img/Loading.svg'

export default function VerseView () {
	const {state: appState, dispatch: appDispatch} = useContext(AppContext)

	function onSwipeCardUp () {
		appDispatch({type: 'increment'})
	}

	function onSwipeCardDown () {
		appDispatch({type: 'show-delete'})
	}

	function onSwipeCardLeft () {
		appDispatch({type: 'next-section'})
	}

	function onSwipeCardRight () {
		appDispatch({type: 'prev-section'})
	}

	return (<>
		<Header />

		<div className="flex-grow"/>

		{appState.verseText &&
			<VerseCard
				onSwipeUp={onSwipeCardUp}
				onSwipeDown={onSwipeCardDown}
				onSwipeLeft={onSwipeCardLeft}
				onSwipeRight={appState.sectionsIndex > 0 ? onSwipeCardRight : null}
			/>
		}

		{!appState.verseText &&
			<LoadingSvg className="animate-pulse" height="1em" fill="#808080"/>
		}

		<div className="flex-grow"/>

		<Footer />
	</>)
}