import { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../App'
import { ReactComponent as LongLogoSvg } from '../img/TSV.svg'
import { ReactComponent as TallyMarkSvg } from '../img/TallyMark.svg'
import { ReactComponent as TsAwardSvg } from '../img/27.svg'
import { DEFAULT_CHAPTER_LENGTH } from '../Data'

export default function Header () {
	const {state: appState} = useContext(AppContext)
	const [tallySetsOpacityCss, setTallySetsOpacityCss] = useState("opacity-0")

	useEffect(() => {
		if (appState.verseCounter < DEFAULT_CHAPTER_LENGTH) {
			setTallySetsOpacityCss("opacity-100")
		}
		else if (appState.verseCounter === DEFAULT_CHAPTER_LENGTH) {
			setTimeout(() => {
				setTallySetsOpacityCss("opacity-0")
			}, 300)
		}
	}, [appState.verseCounter])

	const verseCounter = appState.verseCounter
	const tallyMax = 5

	return (
		<div className="flex flex-row flex-wrap items-center h-4">
			<LongLogoSvg className="basis-[60%] shrink-0" fill="#808080"/>

			<div className="flex flex-row-reverse flex-wrap basis-[40%] shrink-0 h-4">
				{new Array(appState.chaptersRead).fill(0).map((v, i) => {
					return (
						<div
							key={i}
							className={`flex justify-center items-center bg-midgray rounded-lg ml-px mt-px pl-[6px] pr-1 h-4
											text-xs`}>
							<TsAwardSvg fill="#dcdcdc" height="1em"/>
						</div>
					)
				})}
			</div>

			<div
				className={`flex flex-row basis-[60%] shrink-0 mt-1 ${tallySetsOpacityCss} transition-opacity`}>
				{new Array(Math.floor((verseCounter/tallyMax) + 1)).fill(0).map((v, i) => {
					return <TallySet key={i} count={verseCounter - (i * tallyMax)} countMax={tallyMax}/>
				})}
			</div>
		</div>
	)
}

function TallySet ({count, countMax}) {
	const crossBarRef = useRef()

	useEffect(() => {
		if (count < countMax) {
			if (crossBarRef.current) {
				crossBarRef.current.style.backgroundPosition = "100%"
				crossBarRef.current.style.transitionProperty = null
				crossBarRef.current.style.transitionDuration = null
			}
		}
		else if (count >= countMax) {
			crossBarRef.current.style.backgroundPosition = "0%"
			crossBarRef.current.style.transitionProperty = "background-position"
			crossBarRef.current.style.transitionDuration = "150ms"
			crossBarRef.current.style.transitionTimingFunction = "ease-out"
		}
	}, [count, countMax])

	return (
		<div className="flex flex-col basis-[15.5%] shrink-0 mr-1">
			<div className="flex flex-row">
				{new Array(Math.min(count, countMax-1)).fill(0).map((v, i) => {
					return (
						<TallyMark key={i}/>
					)
				})}
			</div>

			<div
				ref={crossBarRef}
				style={{
					backgroundImage: `linear-gradient(to right, #808080 0%, 50%, rgba(0,0,0,0) 50%)`,
					backgroundSize: "202% auto",
					backgroundPosition: "100%",
				}}
				className="h-[2px] -mt-2 -mx-px">
			</div>
		</div>
	)
}

function TallyMark () {
	const [opacityCss, setOpacityCss] = useState("opacity-0")
 
	useEffect(() => {
	  setOpacityCss("opacity-100")
	}, [])
 
	return (
		<TallyMarkSvg
			className={`${opacityCss} basis-1/4 transition-opacity`}
			fill="#808080"
		/>
	)
}
